<template>
  <div class="app-admin-page application-page">
    <ApplicationHeader
      name="options.list"
      :count="count"
      add
      @click="onFilterClick"
    />
    <ApplicationContent
      v-bind="contentAttrs"
      v-on="contentListeners"
      :skeletonList="skeletonList"
      sekeleton
    />
    <ApplicationModal
      v-if="modalVisible"
      v-bind="modalAttributes"
      v-on="modalListeners"
      icon="option-add"
      name="options.modal.name"
      ref="modal"
    />
  </div>
</template>
<script>
import {get, } from "../../helpers/api";
import {getApplicationDefaultMixin, getApplicationModalMixin} from "../../mixins/ScriptPage";
import {
    ContentLoader,
    FormInputElement,
    FormInputList,
    ModalController,
    ModalFormController,
    ScriptPage
} from "../../services/ScriptPage";
import ApplicationHeader from "../../components/admin/ApplicationHeader";
import ApplicationContent from "../../components/admin/ApplicationContent";
import ApplicationModal from "../../components/modals/ApplicationModal";
import {CONTENT_ITEMS_SIZES} from "../../components/admin/ContentItems";

class OptionContentLoader extends ContentLoader {
    constructor() {
        super("/options");
    }
    fetch(filterStr) {
        return super.fetch(filterStr)
            .then(f => {
                return {
                    data: f.response.data,
                    count: f.response.data.length,
                    success: f.response.success
                };
            });
    }
}

export default {
    name: "Option",
    components: {
        ApplicationModal,
        ApplicationContent,
        ApplicationHeader
    },
    mixins: [getApplicationDefaultMixin(
        new ScriptPage(
            new OptionContentLoader(),
            {},
            new ModalFormController(
                new FormInputList("1", "", "", "", [
                    new FormInputElement("id", "common.form.id", "", "hidden"),
                    new FormInputElement("name", "common.form.nameLong", "", "input", null, [["required"]]),
                    new FormInputElement("action", "common.form.type", "", "select", [], [["required"]]),
                    new FormInputElement("function", "common.form.function", "", "select", [], [["required"]]),
                ])
            ),
            new ModalController()
        )),
        getApplicationModalMixin()
    ],
    data() {
        return {
            actions: {},
            functions: {},
        };
    },
    created() {
        this.loadActions();
    },
    methods: {
        parseItemData(item, idx) {
            return [
                {
                    name: "common.table.number",
                    value: (idx + 1),
                    size: CONTENT_ITEMS_SIZES[0],
                    skeleton: this.loading,
                    skeletonHeight: "20px"
                },
                {
                    name: "common.table.name",
                    value: item.name,
                    size: CONTENT_ITEMS_SIZES[3],
                    skeleton: this.loading,
                    skeletonHeight: "20px"

                },
                {
                    name: "common.table.type",
                    value: this.actions[item.action] || "-",
                    size: CONTENT_ITEMS_SIZES[2],
                    skeleton: this.loading,
                    skeletonHeight: "20px"

                },
                {
                    name: "common.table.action",
                    value: this.functions[item.function] || "-",
                    size: CONTENT_ITEMS_SIZES[2],
                    skeleton: this.loading,
                    skeletonHeight: "20px"
                }
            ];
        },
        parseAction(actions) {
            return Object.entries(actions).map(([value, name]) => ({
                value: +value,
                name
            }));
        },
        loadActions() {
            this.actions = [];
            get("/option/actions")
                .then((response) => {
                    if (response.data) {
                        this.actions = response.data;
                        this.page.setFormRenderElement({
                            items: this.parseAction(this.actions)
                        }, "action");
                    }
                });
            get("/option/functions")
                .then((response) => {
                    if (response.data) {
                        this.functions = response.data;
                        this.page.setFormRenderElement({
                            items: this.parseAction(this.functions)
                        }, "function");
                    }
                });
        },
    },

    computed: {
        skeletonList() {
            const list = []
            for (let index = 0; index < 10; index++) {
                list.push({
                    id: index,
                    skeleton: true,
                })
            }
            return list
        }
    }
};
</script>
